import * as React from "react"
import "bootstrap/scss/bootstrap.scss"
import "bootstrap-icons/font/bootstrap-icons.scss"

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const headingAccentStyles = {
  color: "#663399",
}

const paragraphStyles = {
  marginBottom: 48,
}

const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Astrana</title>
      <h1 style={headingStyles}>
        Welcome to Astrana.        
      </h1>
      <p style={paragraphStyles}>
        We're just getting started - please visit again soon. 
      </p><p>
        <b>Follow us:</b>
      </p><p>
        <a href="https://github.com/astrana-project" className="btn btn-outline-secondary me-2" style={{"fontSize": "1.5rem", "color": "black"}}><i className="bi bi-github"></i></a>
        <a href="https://twitter.com/AstranaApp" className="btn btn-outline-secondary me-2" style={{"fontSize": "1.5rem", "color": "black"}}><i className="bi bi-twitter"></i></a>
        <a href="https://medium.com/@astrana" className="btn btn-outline-secondary" style={{"fontSize": "1.5rem", "color": "black"}}><i className="bi bi-medium"></i></a>
      </p><p>
        <img alt="GitHub followers" src="https://img.shields.io/github/followers/astrana-project?style=social" /> &nbsp;
        <img alt="Twitter Follow" src="https://img.shields.io/twitter/follow/AstranaApp?style=social" />
      </p>
    </main>
  )
}

export default IndexPage
